exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-diensten-index-tsx": () => import("./../../../src/pages/diensten/index.tsx" /* webpackChunkName: "component---src-pages-diensten-index-tsx" */),
  "component---src-pages-disclaimer-index-tsx": () => import("./../../../src/pages/disclaimer/index.tsx" /* webpackChunkName: "component---src-pages-disclaimer-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-index-tsx": () => import("./../../../src/pages/jobs/index.tsx" /* webpackChunkName: "component---src-pages-jobs-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-projecten-index-tsx": () => import("./../../../src/pages/projecten/index.tsx" /* webpackChunkName: "component---src-pages-projecten-index-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-vacancy-tsx": () => import("./../../../src/templates/vacancy.tsx" /* webpackChunkName: "component---src-templates-vacancy-tsx" */)
}

